import React, { useState, forwardRef, useImperativeHandle } from 'react';

import { useStyles } from './styles'
import LoaderImage from 'assets/img/common/loading.png'

const Loading = forwardRef((props, ref) => {
  const classes = useStyles();
  const [ isLoading, setIsLoading ] = useState(false);

  useImperativeHandle(ref, () => ({
   showLoading: () => setIsLoading(true),
   hideLoading: () => setIsLoading(false),
  }));

  return <>
    {
      isLoading &&
      <div
        className={classes.loading}
      >
        <img src={LoaderImage} alt='Loading...' className={classes.loading__image} />
      </div>
    }
  </>
});

export default Loading
