import React from 'react';
import Table from './Table';
import { useStyles } from './styles';

const TableWinners = ({ data, index }) => {
  const props = {index}
  const classes = useStyles(props);
  
  const mapDataToTable = (data = [], header = []) => {
    if (data.length > 0) {
      const body = [];
      for (let i = 0; i < 10 ; i+=2) {
        const b = data.slice(i,i + 2)
        if (b.length > 0) {
          body.push(b)
        } else {
          break
        }
      }
      return  {
        header,
        body
      };
    }

    return  {
      header,
      body: data
    };
  };

  const renderTableDesktop = () => {
    const items = data.resuls;
    const table1 = mapDataToTable(items)
    return (
      <div className={classes.winners__table__wrapper}>
        <div className={classes.winners__table}>
          <Table data={table1} />
        </div>
      </div>
    )
  };

  const renderTitle = () => {
    return (
      <div className={classes.winner_title}>
          <span>
            {data.name}
          </span>
      </div>
    )
  }
  let styles = {};
  if (index === 0) {
    styles.backgroundColor = '#f8f1cd'
  }
  return <div style={styles} className={classes.winners}>
    {renderTitle()}
    {renderTableDesktop()}
  </div>
};

export default TableWinners;
