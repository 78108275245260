import { makeStyles } from "@material-ui/core/styles";
import { font } from "config/styles";
import boxNumber from 'assets/img/home/box_number.png';

export const useStyles = makeStyles((theme) => ({
  winners: {
    position: 'relative',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '1560px',
      width: '100%',
    },
  },

  winners__table__wrapper: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      fontSize: font.nomalSize__mobile,
    },
  },

  winners__table: {
    display: 'flex',
    justifyContent: 'center',
    alignItems:'flex-end',
    width: 'auto',
    flex: '1',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '30px'
    },
  },
  
  lives: {
    display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      fontSize: font.nomalSize__mobile,
    },
  },

  live_title: {
    display:'flex',
    justifyContent:'flex-start',
    alignItems:'flex-end',
    width:'100%',
    [theme.breakpoints.down('sm')]: {
      // height: '40px',
    },
    [theme.breakpoints.down('xs')]: {
      // height: '30px',
    },
  },

  header_title: {
    '& span': {
      fontFamily: 'OpenSansExtraBold',
      fontSize: font.largeSize,
      color: 'black',
      [theme.breakpoints.down('sm')]: {
        fontSize: font.largeSize__mobile
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: font.bigSize__mobile
      }
    }
  },
  imageTop: {
    height: '100%',
    width: 'auto'
  },

  live__body: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    justifyContent:'center',
    alignItems:'center',
    flexDirection: 'row',
    padding: '2px 0px 10px 0px'
  },

  live_content: {
    borderRadius: '5px',
    color: '#fff',
    display: 'flex',
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    justifyContent: 'center',
    alignItems: 'center',
    textAglim: 'center',
    padding: '1px',
    margin: '3px',
    width: '40px',
    height: '40px',
    fontSize: '28px',
    backgroundImage: `url(${boxNumber})`,
    fontFamily: font.boldFamily,
    zIndex: '1',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '4px',
      fontSize: '24px',
      width: '35px',
      height: '35px',
      margin: '2px',
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: '2px',
      fontSize: '22px',
      width: '30px',
      height: '30px',
      margin: '1px',
    }
  },
  img_top: {
    transform: "translate(0, -5%)",
    width: '45px',
    marginRight: '5px',
    [theme.breakpoints.down('sm')]: {
      width: '40px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '35px',
      transform: "translate(0, -10%)",
    },
    [theme.breakpoints.down('360')]: {
      width: '30px',
      transform: "translate(0, -10%)",
    },
  }
}));