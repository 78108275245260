import React from 'react';
import Image from 'react-image-webp';
import { useStyles } from './styles'

import Logo from 'assets/img/home/logo.png';
import LogoWepb from 'assets/imgWebp/logo.webp';

const LogoCompany = ({isHomePage, data}) => {
  const classes = useStyles();
  let className = classes.containner
  if (isHomePage) {
    className = classes.containner_home
  }
  return (
    <>
      <div className={className}>
        <Image alt='logo' src={Logo} webp={LogoWepb} className={classes.img_logo} />
      </div>
    </>
  )
};

export default LogoCompany
