import React, { useState, useEffect, useContext} from "react";
import { get, map } from 'lodash';
import {stringToArray} from './../../utils'
import { useTranslation } from 'react-i18next';
import { Background, RouterContext } from 'context/RouterContext';
import Api from 'services/Api';
import TimerView from './../TimerView';

import LiveTopThree from './LiveTopThree'
import TableWinners from './../Home/TableWinners'
import { useStyles } from './styles';

const LivePage = () => {
  	const classes = useStyles();
	const { t } = useTranslation();
	const Router = useContext(RouterContext);
	const [timerData, setTimerData] = useState({
		timeUntilNextResultSeconds: 0
	})
	const [dataMap, setDataMap] = useState({
		drawTime: {},
		prizeList: [],
		singlePrizes: []
	})

  useEffect(() => {
    Router.setBackground(Background.other);
  }, [Router]);
  

	const generateNumbers = (amount)=> {
		let numbers = ''
		for (let index = 0; index < amount; index++) {
			numbers += Math.floor(Math.random() * 10);
		}
		return numbers
	}

	const handleUpdateAgain = (data)=> {
		const dMap = mapDataToTable(data)
		setDataMap(dMap);
	}

	const mapDataToTable = (data) => {
		const results = get(data, 'result', {})
		const prizeListData = get(results, 'prizeList', [])
		const singlePrizeListData = get(results, 'singlePrizeList', [])
		let stringNumbers = generateNumbers(6)
		let IsRepeatRandom = false
		const singlePrizes = map(singlePrizeListData, (item, index) => {
			if (item.result === '') {
				IsRepeatRandom = true
				return {
					...item,
					result: stringNumbers,
					index: index + 1,
					numbers: stringToArray(stringNumbers)
				}
			}
			return {
				...item,
				index: index + 1,
				numbers: stringToArray(item.result)
			}
		})

		const prizeList = map(prizeListData, (item, index) => {
			return {
				...item,
				id: index,
				resuls: item.result.map((ressultString) => {
					if (ressultString === '') {
						IsRepeatRandom = true
						return stringToArray(stringNumbers)	
					}
					return stringToArray(ressultString)
				})
			}
		})
		
		if (IsRepeatRandom) {
			//save to random again
			setTimeout(()=>handleUpdateAgain(data), 100)
		}

		return {
			drawTime: {
				date: `${t('WinningResults')}`,
				time: `${data.day_of_week}, ${data.date}`,
				time_until_next_result: data.time_until_next_result,
				timeUntilNextResultSeconds: data.time_until_next_result_seconds

			},
			singlePrizes,
			prizeList
		}
	}

	useEffect(() => {
		Api.requestLives().then((res) => {
			const isSuccess = get(res, 'data.success', false);
			if (!isSuccess) {
				// can not get data
				//render maintainer page
			} else {
				const data = get(res, 'data.data', {});
				const dMap = mapDataToTable(data)
				setDataMap(dMap);
				setTimerData({
					timeUntilNextResultSeconds: data.time_until_next_result_seconds
				})
			}
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t])

	return (
		<div className={classes.container_page} style={{ animation: 'fadeIn ease-in .1s' }}>
			<div className={classes.timer__container}>
				<TimerView  data={timerData} />
			</div>
			<br />
			<div className={classes.container}>
				<div className={classes.timeLiveDraw} >
					<div className={classes.liveScoreSmall}>{dataMap.drawTime.date}</div>
					<div className={classes.liveScoreSmaller}>{dataMap.drawTime.time}</div>
				</div>
				<br />
				<LiveTopThree data={dataMap.singlePrizes} />
				{map(dataMap.prizeList, (item, index) => {
					return <TableWinners key={index} index={index} data={item} />
				})}
			</div>
		</div>
	)
};

export default LivePage