import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from 'context/AuthContext'
import { Background, RouterContext } from 'context/RouterContext';
import { map, get } from 'lodash';
import TableTopThreeView from './../Home/TableTopThree'
import TableWinners from './../Home/TableWinners'
import { useStyles } from './styles';
import Api from 'services/Api';

const Home = (props) => {
	const classes = useStyles()
	const Router = useContext(RouterContext);
	const authContent = useContext(AuthContext);
	const [mapDataDefault, setMapDataDefault] = useState({
		drawTime: {},
		prizeList: [],
		singlePrizes: []
	})

	useEffect(() => {
		Api.requestHomePage().then((res) => {
			const isSuccess = get(res, 'data.success', false);
			if (isSuccess) {
				const data = get(res, 'data.data', {});
				const hData = authContent.mapDataHomePage(data)
				setMapDataDefault(hData)
			}
		});
	}, [authContent]);

	useEffect(()=> {
		Router.setBackground(Background.home);
	}, [Router])
	
	//============================//
	//
	//============================//
	
	const renderFirstMultPrize = ()=> {
		if (mapDataDefault.prizeListFirst && mapDataDefault.prizeListFirst.length > 0) {
			return <div className={classes.content}>
			{
				map(mapDataDefault.prizeListFirst, (item, index) => {
					return <TableWinners key={index} index={index} data={item} />
				})
			}
			</div>
		}
		return null;
	}
	
	return(
		<div className={classes.container_page} style={{ animation: 'fadeIn ease-in .3s' }}>
			<div className={classes.contentSingle}>
				<TableTopThreeView data={mapDataDefault.singlePrizes} />
			</div>
			{renderFirstMultPrize()}
			<br />
		</div>
	)
};

export default Home
