import React, { useContext } from 'react';
import { useStyles } from './AppStyles';
import Routes from './routers'
import { AuthContext } from 'context/AuthContext'

function App() {
	const classes = useStyles();
	const Auth = useContext(AuthContext);
	return (
		<AuthContext.Provider value={{ ...Auth}}>
			<div className={classes.App}>
				<Routes />
			</div>
		</AuthContext.Provider>
	);
}

export default App;
