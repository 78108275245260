import { createContext } from 'react';

import BackgroundHome from 'assets/img/home/BG.png';
import BackgroundHomeWebp from 'assets/imgWebp/BG.webp';

import BackgroundOther from 'assets/img/home/BG_2.png';
import BackgroundOtherWebp from 'assets/imgWebp/BG_2.webp';


export const Background = {
  home: {webp: BackgroundHomeWebp, src: BackgroundHome},
  other: {webp: BackgroundOtherWebp, src: BackgroundOther},
};

export const RouterContext = createContext({
  background: '', // default value
  setBackground: () => {},
});
