import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  loading: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    backgroundColor: '#00000080',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 99999,
  },

  loading__image: {
    width: 48.3,
    height: 46.7,
    animation: 'rotation 1s steps(12) infinite',

    [theme.breakpoints.down('sm')]: {
      width: '7.546875vw',
      height: '7.296875vw',
    },
  },
}));
