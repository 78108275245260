import React from 'react';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

const width5Columns = ['25%','25%', '25%','25%']
const backgroundColor = ['#22599a','#e0aa37', '#54d472','#7aa9f0']
const RTable = ({ data = {}, style, onClick, onMouseMove, onMouseLeave, selectedIndex = -1 }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const renderHeader = () => {
		const { header } = data;
		return <div className={classes.table__header_1} >
			{
				map(header, (head, i) => {
					const style = {
						width:  width5Columns[i],
						backgroundColor: backgroundColor[i]
					}

					if (i !== 3) {
						style.borderRight = '1px solid black';
					}
					
					return <div className={classes.row_header_table} style={style} key={i}>{head}</div>
				})
			}
		</div>
	};

	const renderBody = () => {
		const { body } = data;
		if (body && body.length <= 0) {
			return <div className={classes.body_bg_noresult}><span> {t('NoResults')}</span></div>
		}
		return map(body, (row, i) => {
			return <div key={i} className={classes.body_bg_1}>
				{
					map(row, (data, j) => {
						let style = {
							width:  width5Columns[j]
						};

						if (j === 0) {
							style.color = 'black';
							style.flexDirection = 'column';
						}

						if (j !== 3) {
							style.borderRight = '1px solid black';
						}
						if (Array.isArray(data)) {
							let styleCircleRow = {
								backgroundColor: backgroundColor[j]
							}
							return <div style={style} key={j} className={classes.row_table}>
								{map(data, (item, index) => <div  className={classes.row_circle} style={styleCircleRow} key={index}>{item}</div>)}
							</div>
						} else if (typeof data === "object" && data.name === 'day') {
							return <div style={style} className={classes.row_table_break} key={j}>
								<div className={classes.row_table_sub_top} >{data.dayOfWeek}</div>
								<div className={classes.row_table_sub_bottom} >{data.date}</div>
							</div>
						}
						return <div style={style} className={classes.row_table} key={j}>{data}</div>
					}
					)
				}
			</div>
		})
	};

	return <div className={classes.table} style={style}>
		<div className={classes.table__header__containner}>
			{renderHeader()}
		</div>
		<div className={classes.table__body_1}>
			{renderBody()}
		</div>
	</div>
};

export default RTable;
