
import HTTPService from './HTTPServices';
const ApiDomain = process.env.REACT_APP_API_DOMAIN;

const Api = {};
Api.requestHomePage = function () {
    return HTTPService.sendPostReqeust(ApiDomain + '/result')
};

Api.requestLives = function () {
    return HTTPService.sendPostReqeust(ApiDomain + '/result_live')
};

Api.requestPageResult = function (page) {
    const params = {
        page: page, 
        page_size: 7
    }
    
    return HTTPService.sendPostReqeust(ApiDomain + '/result_paging', undefined, params)
}

Api.requestPageResultSearch = function (dateSearch = '') {
    const params = {
        date_search: dateSearch,
        page:0,
        page_size: 10
    }
    
    return HTTPService.sendPostReqeust(ApiDomain + '/result_search', undefined, params)
}

Api.requestMeta = function (endpoint = '') {
    return HTTPService.sendPostReqeust(ApiDomain + '/metadata', undefined, {endpoint})
}

export default Api;