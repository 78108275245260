import { makeStyles } from "@material-ui/core/styles";
import { font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  winners: {
    position: 'relative',
    display: 'flex',
    // flex: '1',
    // width: '100%',
    borderRadius: '5px',
    justifyContent:'center',
    flexDirection: 'column',
    alignItems: 'flex-start',
    // backgroundColor: '#2e041c',
    [theme.breakpoints.down('sm')]: {
      // maxWidth: '1560px',
      // width: '100%',
    },
  },

  winner_title: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    verticalAlign:'text-bottom',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      width: '100%'
    },
    '& span': {
      // display: 'flex',
      textTransform: 'uppercase',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
      fontFamily: font.boldFamily,
      margin: '0px',
      height: 'auto',
      color: '#ffbd76',
      display: 'inline-block',
      verticalAlign:'middle',
      fontSize: font.largeSize,
      [theme.breakpoints.down('sm')]: {
        fontSize: font.largeSize__mobile,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '4.2vw',
      }
    }
  },
  title_container: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    verticalAlign:'text-bottom',
    height: '40px',
    '& span': {
      textTransform: 'uppercase',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
      fontFamily: font.boldFamily,
      margin: '0px',
      height: 'auto',
      color: '#ffbd76',
      display: 'inline-block',
      verticalAlign:'bottom',
      fontSize: font.largeSize,
      [theme.breakpoints.down('sm')]: {
        fontSize: font.largeSize__mobile,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '4.2vw',
      }
    }
  },

  winners__table__wrapper: {
    position: 'relative',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'scroll',
    backgroundSize: "auto 100%",
    // backgroundColor: '#fff',
    animation: 'fadeIn ease-in .5s',
    transition: 'all .5s ease-in-out',

    display: 'flex',
    width: 'auto',
    alignItems:'center',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: font.nomalSize__mobile,
    },
  },

  winners__table: {
    display: 'flex',
    justifyContent: 'center',
    flex: '1',
    zIndex: 2
    // backgroundColor: '#2e041c',
    
  },
  image_bg: {
    display: 'flex',
    position: 'absolute',
    height: '100%',
    zIndex: 0,
  },

  contanner__image: {
    display: 'flex',
    position: 'relative',
    height: '100%',
    width: '80px',
  },

  image_ball: {
    position: 'absolute',
    zIndex: 1,
    top: '-8%',
    left: '85%',
    height: '100px',
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: '90px',
    },
    [theme.breakpoints.down('xs')]: {
      margin:'2px 2px 0px 2px',
      height: '80px',
    }
  },
}));
