import React from 'react';
import Image from 'react-image-webp';
import { useStyles } from './styles';

import cupTop1 from 'assets/img/home/cup_top_1.png';
import cupTop1Wepb from 'assets/imgWebp/cup_top_1.webp';

import cupTop2 from 'assets/img/home/cup_top_2.png';
import cupTop2Wepb from 'assets/imgWebp/cup_top_2.webp';

import cupTop3 from 'assets/img/home/cup_top_3.png';
import cupTop3Wepb from 'assets/imgWebp/cup_top_3.webp';


const imageCups = [
	{
		url: cupTop1,
		urlWebp: cupTop1Wepb
	},
	{
		url: cupTop2,
		urlWebp: cupTop2Wepb
	},
	{
		url: cupTop3,
		urlWebp: cupTop3Wepb
	}
]
const LiveTopThree = ({ data = [] }) => {
	const classes = useStyles()

	const renderBody = (item) => {
		const { numbers } = item;
		if (numbers) {
			return numbers.map((num, i) => {
				return <div key={i} className={classes.live_content}>
					{num}
				</div>
			})
		}
		return null;
	};

	return <div className={classes.winners}>
		<div className={classes.winners__table__wrapper}>
			{
				data.map((item, index) => {
					const cupImage = imageCups[index] || {}
					return <div key={index} className={classes.winners__table}>
						<Image alt='cupTop1' src={cupImage.url} webp={cupImage.urlWebp} className={classes.img_top} />
						<div className={classes.lives}>
							<div className={classes.live_title}>
								<div className={classes.header_title}> <span> {item.name}
									</span>
								</div>
							</div>
							<div className={classes.live__body}>
								{renderBody(item)}
							</div>
						</div>
					</div>
				})
			}
		</div>
	</div>
};

export default LiveTopThree;