import { createContext } from 'react';
import { get, map } from 'lodash';
import Api from 'services/Api';
import { stringToArray } from './../utils'

const mapDataHomeResult = function (data) {
	const periodNext = get(data, 'period', {})
	const results = get(data, 'result', [])
	const prizeListData = get(results, 'prizeList', [])
	const singlePrizeListData = get(results, 'singlePrizeList', [])
	const singlePrizes = map(singlePrizeListData, (item, index) => {
		return {
			...item,
			index: index + 1,
			numbers: stringToArray(item.result)
		}
	})
	
	const prizeList = map(prizeListData, (item, index) => {
		return {
			...item,
			id: index,
			resuls: item.result.map((ressultString) => {
				return stringToArray(ressultString)
			})
		}
	})
	
	return {
		timeNextDraw: {
			...periodNext
		},
		singlePrizes,
		prizeList,
		prizeListFirst: prizeList.slice(0, 2),
		prizeListSecond: prizeList.slice(2, 5)
	}
}

export const AuthContext = createContext({
	homeFunc: null,
	dataList: {},
	resultHomeData: {
		timeNextDraw: {},
		prizeList: [],
		singlePrizes: [],
		prizeListFirst: [],
		prizeListSecond: []
	},
	mapDataHomePage: mapDataHomeResult,
	requestAuth: Api.requestHomePage,
	requestPeriodDateDropList: Api.requestPeriodDateList
});
