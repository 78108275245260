import { makeStyles } from "@material-ui/core/styles";

import { color, font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
    borderSpacing: '0px',
    overflow: 'hidden',
    '& th, td': {
    },
    '& table': {
      borderCollapse: 'collapse',
      overflow: 'hidden'
    },
    fontSize: font.largeSize,
    [theme.breakpoints.down('sm')]: {
      fontSize: font.largeSize__mobile,
    },
  },

  table__header: {
    '& tr': {
      height: '60px',
      backgroundColor: color.tableHeader,
    },
    '& th': {
      textAlign: 'left',
    },

    [theme.breakpoints.down('sm')]: {
      '& tr': {
        height: '9.375vw',
      },
    },
  },

  table__body: {
    '& tr': {
      height: '40px',
    },

    [theme.breakpoints.down('sm')]: {
      '& tr': {
        height: '6.25vw',
      },
    },
  },
  table_container_numers: {
    display: 'flex',
    alignItems:'center',
    justifyContent: 'center',
    flexDirection: 'colum',
    margin:'6px',
  },
  table_number: {
    display: 'flex',
    alignItems:'center',
    justifyContent: 'center',
    borderRadius: '5px',
    width: '35px',
    height: '35px',
    fontSize: '28px',
    padding: '1px',
    margin: '2px',
    fontFamily: font.boldFamily,
    boxSizing: 'content-box',
    color: '#fff',
    backgroundColor: '#22599a',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '4px',
      fontSize: '24px',
      width: '32px',
      height: '32px',
      margin: '2px',
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: '2px',
      fontSize: '5vw',
      width: '5vw',
      height: '5vw',
      margin: '1px',
    }
  }
}));
