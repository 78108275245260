import axios from 'axios';
import { KEY_NAME, KEY_TOKEN } from 'config/localStorageKey';

const HTTPService = {
	sendRequest: (url, method, headerParams, body, { timeout = 60000 } = {}, responseType = 'json') => {
		const headers = headerParams || {};
		const request = {url, method, timeout, headers, responseType};
		if (!method.match(/get|head|delete/)) {
			request.data = body || {};
		}
		if (method === 'delete') {
			request.params = body;
		}
		return new Promise((resolve) => {
			axios.request(request).then((res) => {
				if (res.status >= 200 && res.status < 300) {
					resolve(res);
				} else {
					resolve({ isError: true, ...res.data });
				}
			}).catch(err => resolve({ isError: true, err }));
		});
	},

	sendGetReqeust: (url, headerParams, body)=> {
		return HTTPService.sendRequest(url, 'GET', headerParams, body)
	},

	sendPostReqeust: (url, headerParams, body)=> {
		return HTTPService.sendRequest(url, 'POST', headerParams, body)
	},

	sendRequestWithToken: (method, headerParams, body, { timeout = 60000 } = {}, responseType = 'json') => {
		const headers = headerParams || {};
		const name = localStorage.getItem(KEY_NAME);
		const token = localStorage.getItem(KEY_TOKEN);
		const auth = {
			'AUTH-USERNAME': name,
			'AUTH-TOKEN': token,
		};

		return HTTPService.sendRequest(method, { ...headers, ...auth }, body, { timeout }, responseType);
	},

	downloadFile: (method, url, headerParams, body, fileName, { timeout = 60000 } = {}, responseType = 'blob') => {
		const headers = headerParams || {};
		const token = JSON.parse(localStorage.token || localStorage.verifyToken || '{}');
		headers.authorization = `${token.token_type} ${token.access_token}`;
		return HTTPService.sendRequest(method, url, headers, body, { timeout }, responseType)
			.then((response) => {
				const endp = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = endp;
				link.setAttribute('download', `${fileName}.pdf`);
				document.body.appendChild(link);
				link.click();
			});
	},

	upload: (method, url, data, onUpload) => new Promise((resolve) => {
		const token = JSON.parse(localStorage.token || '{}');
		axios({
			url,
			method,
			headers: {
				'Content-Type': data.type,
				'Access-Control-Allow-Origin': '*',
				authorization: `${token.token_type} ${token.access_token}`,
			},
			data,
			onUploadProgress: e => onUpload && onUpload(e),
		})
			.then(res => resolve(res))
			.catch(err => resolve({ isError: true, ...err.response }));
	}),
};

export default HTTPService;
