import { makeStyles } from "@material-ui/core/styles";

import {font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  winners: {
    // width: '100%',
    position: 'relative',
    padding: '5px',
    // backgroundColor: '#2e041c',
    margin: '80px 100px 20px 0px',
    // height: '200px',
    borderRadius: '5px',
    [theme.breakpoints.down('sm')]: {
      margin: '60px 0px 10px 0px',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '30px 0px 10px 0px',
    }
  },


  winners__table__wrapper: props => ( {
    display: 'flex',
    width: '100%',
    zIndex: 1,
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      fontSize: font.nomalSize__mobile,
    },
  }),

  winners__table: {
    display: 'inline-block',
    width: '100%',
    margin: '1px',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  winner_title: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
    marginLeft: '10px',
    zIndex: 1,
    '& span': {
      fontFamily: font.boldFamily,
      margin: '0px',
      // height: '35px',
      color: '#22599a',
      fontSize: font.largeSize,
      [theme.breakpoints.down('sm')]: {
        fontSize: font.largeSize__mobile,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '4.2vw',
      }
    }
  },

  image_title: {
    height: '12px',
    width: 'auto',
    margin:'2px 2px 0px 2px',
    [theme.breakpoints.down('sm')]: {
      margin:'2px 2px 0px 2px',
    }
  },
  image_bg: {
    display: 'flex',
    position: 'absolute',
    height: '180%',
    zIndex: 0,
    bottom:'-20%',
    left: '-10%',
    [theme.breakpoints.down('xs')]: {
      height: '160%',
    }
  },
  image_bg_2: {
    display: 'flex',
    position: 'absolute',
    height: '140%',
    zIndex: 0,
    bottom:'-20%',
    left: '-5%'
  },
}));
