import React from 'react';
import Table from 'components/Table';
import { useStyles } from './styles';
import Image from 'react-image-webp';
import './styles.css'

import bg1 from 'assets/img/home/b.png';
import bg1Wepb from 'assets/imgWebp/b.webp';

import bg2 from 'assets/img/home/c.png';
import bg2Wepb from 'assets/imgWebp/c.webp';

const TableWinners = ({ data, index }) => {
  const props = {index}
  const classes = useStyles(props);
  
  let imageData = {
    className: classes.image_bg,
    url: bg1,
    urlWepb: bg1Wepb,
  }

  if (index % 2 !== 0) {
    imageData = {
      className: classes.image_bg_2,
      url: bg2,
      urlWepb: bg2Wepb,
    }
  }
  const mapDataToTable = (data = [], header = []) => {
    if (data.length > 0) {
      const body = [];
      for (let i = 0; i < 10 ; i+=3) {
        const b = data.slice(i,i + 3)
        if (b.length > 0) {
          body.push(b)
        } else {
          break
        }
      }
      return  {
        header,
        body
      };
    }

    return  {
      header,
      body: data
    };
  };

  const renderTableDesktop = () => {
    const items = data.resuls;
    const table1 = mapDataToTable(items)
    return (
      <div className={classes.winners__table__wrapper}>
        <div className={classes.winners__table}>
          <Table data={table1} />
        </div>
      </div>
    )
  };

  const renderTitle = () => {
    return (
      <div className={classes.winner_title}>
          <span>
            {data.name}
          </span>
      </div>
    )
  }

  return <div className={classes.winners}>
    <Image className={imageData.className} alt='ball' src={imageData.url} webp={imageData.urlWepb} />
    {renderTitle()}
    {renderTableDesktop()}
  </div>
};

export default TableWinners;
