import React from 'react';
import { useStyles } from './styles';

const Numbers = ({ data = {}, index = 0 }) => {
	const classes = useStyles();
	const renderHeader = () => {
		const { id, name } = data;
		const classNameRainbow = `header_title_${id}`
		return (
			<div className={classes.header_title}> <span className={classNameRainbow}> {name}</span> </div>
		)
	};

	const renderBody = () => {
		const { numbers } = data;
		if (numbers) {
			return numbers.map((num, i) => {
				return <div key={i} className={classes.number_content}>
					{num}
					</div>
			})
		}
		return null;
	};

	

	return <div className={classes.numbers}>
		<div className={classes.number_title}>
			{renderHeader()}
		</div>
		<div className={classes.number__body}>
			<div className={classes.number__body_background}/>
			{renderBody()}
		</div>
	</div>
};

export default Numbers;
