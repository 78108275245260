import { makeStyles } from "@material-ui/core/styles";
import {layout } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  containner: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    width: '100%',
    minHeight: layout.headerHeighBg,
    marginBottom: '5px',
    [theme.breakpoints.down('sm')]: {
      minHeight: layout.headerHeighBg__mobile,
      '& ball__left': {
        display: 'flex'
      }
    },
  },
  containner_home: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    width: '100%',
    minHeight: layout.headerHeighHomeBg,
    marginBottom: '5px',
    [theme.breakpoints.down('sm')]: {
      minHeight: layout.headerHeighHomeBg__mobile,
      '& ball__left': {
        display: 'flex'
      }
    },
    // [theme.breakpoints.down('xs')]: {
    //   minHeight: layout.headerHeighHomeBg__xs__mobile,
    //   '& ball__left': {
    //     display: 'flex'
    //   }
    // },
  },

  img_logo: {
    position: 'absolute',
    left: '0%',
    bottom: '5%',
    width: '30%',
    [theme.breakpoints.down('sm')]: {
      left: '10px',
      bottom: '5%',
      width: '30%',
    },
    [theme.breakpoints.down('xs')]: {
      transform: 'translateX(-50%)',
      left: '50%',
      bottom: '10%',
      width: '50%',
      minWidth: '190px'
    },
  },

  img_logo_home_page: {
    position: 'absolute',
    transform: 'translateX(-50%)',
    left: '50%',
    bottom: '55%',
    width: '38%',
    zIndex: '1',
    [theme.breakpoints.down('md')]: {
      bottom: '130px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '50%',
      left: '50%',
      top: '15%',
    },
  },
}));
