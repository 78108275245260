import { makeStyles } from "@material-ui/core/styles";
import { font } from "../../../config/styles";
export const useStyles = makeStyles((theme) => ({
  table: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    width: '100%',
    // marginBottom:'2vh',
    backgroundColor:'white',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.2vw',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.8vw',
    }
  },

  table__header__containner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    border: '1px solid black',
    width: '100%',
    '& div': {
      height: '60px',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        height: '50px'
      },
      [theme.breakpoints.down('xs')]: {
        height: '40px'
      }
      
    },
    '& th:nth-child(1)': {
      borderTopLeftRadius: '6px',
    },
    '& th:nth-child(2)': {
      borderTopRightRadius: '6px',
    },
  },

  table__header_1: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    // backgroundColor:'#1c0414',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    [theme.breakpoints.down('sm')]: {
      borderTopLeftRadius: '0px',
    borderTopRightRadius: '0px',
    },
    '& :nth-child(1)': {
      paddingLeft: '10px',
    },
    '& div': {
      textAlign: 'center',
      // height: '40px',
      // paddingLeft: '10px' 
    },

  },

  row_header_table: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    fontFamily: font.boldFamily
  },

  table__body_1: {
    display: 'flex',
    width: '100%',
    borderLeft: '1px solid black',
    borderRight: '1px solid black',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& div': {
      textAlign: 'center'
    },
  },

  row_table: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    color: 'white',
    zIndex: '1',
    height: '100%',
    fontSize: '18px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
    [theme.breakpoints.down('360')]: {
      fontSize: '6px'
    }
  },

  row_table_sub_top: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: 'white',
    zIndex: '1',
  },

  row_table_sub_bottom: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: 'white',
    zIndex: '1',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.0vw',
      color: 'red'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.1vw',
      color: 'red',
      lineHeight: '80%',
    }
  },

  row_table_break: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    color: 'white',
    fontSize: '18px',
    lineHeight: 'normal',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '9px',
    },
    [theme.breakpoints.down('360')]: {
      fontSize: '7px',
    }
  },

  row_circle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px',
    width: '30px',
    height: '30px',
    // maxHeight: '30xp',
    backgroundColor:'black',
    color: 'white',
    margin:'3px',
    boxSizing: 'content-box',
    fontFamily: font.boldFamily,
    [theme.breakpoints.down('xs')]: {
      width: '5vw',
      height: '5vw',
      borderRadius: '2px',
      fontSize: '2.8vw',
      margin: '1px',
    }
  },

  body_bg_1: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '60px',
    borderBottom: '1px solid black',
    [theme.breakpoints.down('sm')]: {
      height: '60px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '40px',
    }
  },

  body_bg_noresult: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    height: '60px',
    borderBottom: '1px solid black',
    [theme.breakpoints.down('sm')]: {
      height: '60px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '40px',
    },
    '& span': {
      display: 'flex',
      color: 'black',
      justifyContent: 'center',
      alignItems: 'center',
      boxSizing: 'content-box',
      fontFamily: font.boldFamily,
    }
  },

  // body_bg_2: {
  //   position: 'relative',
  //   display: 'flex',
  //   width: '100%',
  //   alignItems: 'center',
  //   justifyContent: 'space-round',
  //   height: '50px',
  //   borderStyle: 'inset',
  //   // borderBottom: '1px solid black',
  //   // borderTop: '0px solid black',
  //   // borderLeft: '0px solid black',
  //   // borderRight: '0px solid black',
  //   backgroundColor:'#490b32',
  //   [theme.breakpoints.down('sm')]: {
  //     height: '50px',
  //   },
  //   [theme.breakpoints.down('xs')]: {
  //     height: '40px',
  //   },
  // },

  body__background: {
    position:'absolute',
    left: '0',
    top: '0',
    display: 'flex',
    width: '100%',
    height: 'calc(100% + 1px)',
    backgroundColor: '#2c041e',
    // borderBottom: '1px solid black',
    // opacity: '0.3',
    zIndex: '0'

  },
  body__background_2: {
    position:'absolute',
    left: '0',
    top: '0',
    display: 'flex',
    width: '100%',
    height: 'calc(100% + 1px)',
    backgroundColor: '#490b32',
    borderBottom: '1px solid black',
    // opacity: '0.3',
    zIndex: '0'

  }

}));
