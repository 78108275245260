import React from 'react';
import { map } from 'lodash';

import { useStyles } from './styles';

const Table = ({data = {}, hasHeader}) => {
  const classes = useStyles();

  const renderHeader = () => {
    const { header } = data;
    if (hasHeader) {
      return <tr>
        {
          map(header, (head, i) =>
            <th key={i}>{head}</th>
          )
        }
      </tr>
    }
    return null;
  };

  const renderBody = () => {
    const { body } = data;

    return map(body, (row, i) => <tr key={i}>
      {
        map(row, (item, j) => {
          if (item.length && item.length > 0) {
            return <td key={j}><div className={classes.table_container_numers}> {map(item, (number, z)=><span className={classes.table_number} key={z}>{number}</span>)}</div></td>
          }
          return <td key={j}>{item}</td>
        })
      }
    </tr>)
  };

  return <table className={classes.table}>
    <thead className={classes.table__header}>
      {renderHeader()}
    </thead>
    <tbody className={classes.table__body}>
      {renderBody()}
    </tbody>
  </table>
};

export default Table;
