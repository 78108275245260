import { makeStyles } from "@material-ui/core/styles";

import {font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  winners: {
    width: '100%',
    display:'flex',
    justifyContent:'flex-start',
    alignItems: 'center',
    // marginTop: '10px',
    position: 'relative',
    padding: '5px',
    backgroundColor: '#d1eaf9',
    borderBottom: `1px solid #000`,
    borderLeft: `1px solid #000`,
    borderRight: `1px solid #000`,
    [theme.breakpoints.down('sm')]: {
      // marginBottom: '10px',
    },
    [theme.breakpoints.down('xs')]: {
      // marginBottom: '5px',
    }
  },


  winners__table__wrapper: props => ( {
    display: 'flex',
    width: '70%',
    zIndex: 1,
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      fontSize: font.nomalSize__mobile,
    },
  }),

  winners__table: {
    display: 'inline-block',
    width: '100%',
    margin: '1px',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  winner_title: {
    position: 'relative',
    display: 'flex',
    // flex: '0.3',
    width: '20%',
    alignItems: 'center',
    marginLeft: '10px',
    justifyContent: 'center',
    zIndex: 1,
    '& span': {
      fontFamily: 'OpenSansExtraBold',
      margin: '0px',
      // height: '35px',
      color: 'black',
      fontSize: '18px',
      textTransform: 'uppercase',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
      }
    }
  },

  image_title: {
    height: '12px',
    width: 'auto',
    margin:'2px 2px 0px 2px',
    [theme.breakpoints.down('sm')]: {
      margin:'2px 2px 0px 2px',
    }
  },
  image_bg: {
    display: 'flex',
    position: 'absolute',
    height: '120%',
    zIndex: 0,
    bottom:'-10%'
  },
}));
