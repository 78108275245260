import React from 'react';
import TableNumber from 'components/Numbers';
import { useStyles } from './styles';
import Image from 'react-image-webp';

import ball from 'assets/img/home/ball.png';
import ballWepb from 'assets/imgWebp/ball.webp';

import Background from 'assets/img/home/a.png';
import BackgroundWepb from 'assets/imgWebp/a.webp';

import './styles.css'

const TableTopThree = ({data}) => {
  const classes = useStyles()

  const  renderImage =  ()=> {
    if(data && data.length > 0) {
		return <>
			<Image className={classes.image_bg} alt='background result pool top three' src={Background} webp={BackgroundWepb} />
			<Image className={classes.image_ball} alt='ball' src={ball} webp={ballWepb} />
		</>
	}
	return null
  }

  return <div className={classes.winners}>
      <div className={classes.winners__table__wrapper}>
        {renderImage()}
        {
          data.map((item, index)=> {
            return <div key={index} className={classes.winners__table}>
                <TableNumber data={item} index={index} />
            </div>
          })
        }
      </div>
  </div>
};

export default TableTopThree;
