import React from "react";
import { useTranslation } from "react-i18next";

import Image from 'react-image-webp';
import { StyledContainerFooter } from "config/styles";
import { useStyles } from "./styles";

import footerPng1 from 'assets/img/footer/footer_1.png';
import footerPng1Wepb from 'assets/imgWebp/footer_1.webp';
import footerPng2 from 'assets/img/footer/footer_2.png';
import footerPng2Wepb from 'assets/imgWebp/footer_2.webp';
import footerPng3 from 'assets/img/footer/footer_3.png';
import footerPng3Wepb from 'assets/imgWebp/footer_3.webp';
import footerPng4 from 'assets/img/footer/footer_4.png';
import footerPng4Wepb from 'assets/imgWebp/footer_4.webp';
import footerPng5 from 'assets/img/footer/footer_5.png';
import footerPng5Wepb from 'assets/imgWebp/footer_5.webp';
import footerPng6 from 'assets/img/footer/footer_6.png';
import footerPng6Wepb from 'assets/imgWebp/footer_6.webp';

import bfooterPng1 from 'assets/img/footer/B1.png';
import bfooterPng1Wepb from 'assets/imgWebp/B1.webp';
import bfooterPng2 from 'assets/img/footer/B2.png';
import bfooterPng2Wepb from 'assets/imgWebp/B2.webp';
import bfooterPng3 from 'assets/img/footer/B3.png';
import bfooterPng3Wepb from 'assets/imgWebp/B3.webp';
import bfooterPng4 from 'assets/img/footer/B4.png';
import bfooterPng4Wepb from 'assets/imgWebp/B4.webp';
import bfooterPng5 from 'assets/img/footer/B5.png';
import bfooterPng5Wepb from 'assets/imgWebp/B5.webp';


const footerData = [
	{
		id: 1,
		name: 'company1',
		url: footerPng1,
		webUrl: footerPng1Wepb
	}, {
		id: 2,
		name: 'company2',
		url: footerPng2,
		webUrl: footerPng2Wepb
	} , {
		id: 3,
		name: 'company2',
		url: footerPng3,
		webUrl: footerPng3Wepb
	}  , {
		id: 4,
		name: 'company2',
		url: footerPng4,
		webUrl: footerPng4Wepb
	}  , {
		id: 5,
		name: 'company2',
		url: footerPng5,
		webUrl: footerPng5Wepb
	}  , {
		id: 6,
		name: 'company2',
		url: footerPng6,
		webUrl: footerPng6Wepb
	}
]

const footerDataPayment = [
	{
		id: 1,
		name: 'company1',
		url: bfooterPng1,
		webUrl: bfooterPng1Wepb
	}, {
		id: 2,
		name: 'company2',
		url: bfooterPng2,
		webUrl: bfooterPng2Wepb
	} , {
		id: 3,
		name: 'company2',
		url: bfooterPng3,
		webUrl: bfooterPng3Wepb
	}  , {
		id: 4,
		name: 'company2',
		url: bfooterPng4,
		webUrl: bfooterPng4Wepb
	}  , {
		id: 5,
		name: 'company2',
		url: bfooterPng5,
		webUrl: bfooterPng5Wepb
	}
]

const Footer = () => {
	const { t } = useTranslation()
	const classes = useStyles();
	const handleClickImage = (e)=> {
		console.log('click image');
	}
	const companyName = t('CompanyName')
	let footerContent = t('FooterCopyright');
	footerContent = footerContent.replace('[company_name]',companyName)
	
	return <div className={classes.footer}>
		<StyledContainerFooter>
			<div className={classes.footerClass}>
				<div className={classes.footerLeft}>
					<div className={classes.footerTextLeft}>
						{
							footerData.map((itemIcon, index)=><Image onClick={handleClickImage} key={index} alt='compay partnner' src={itemIcon.url} webp={itemIcon.webUrl} className={classes.imageItem} />)
						}
					</div>
				</div>
				<div className={classes.footerRight}>
					<span>{t('FooterText1')}</span>
				</div>
				<div className={classes.footerRight}>
					<span className={classes.footerDeposit}>{t('FooterText2')}</span>
				</div>
				<div className={classes.footerLeft}>
					<div className={classes.footerTextLeft}>
						{
							footerDataPayment.map((itemIcon, index)=><Image onClick={handleClickImage} key={index} alt='compay partnner' src={itemIcon.url} webp={itemIcon.webUrl} className={classes.imageItemFlag} />)
						}
					</div>
				</div>
				<div className={classes.footerRight}>
					{footerContent}
				</div>
			</div>
		</StyledContainerFooter>
	</div>
};

export default Footer
