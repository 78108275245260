import React, { useState, useEffect, useContext } from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Background, RouterContext } from 'context/RouterContext';
import Home from 'pages/Home';
import Lives from 'pages/Lives';
import Results from 'pages/Results';
import Menu from './shared/Menu';
import Footer from './shared/Footer';
import LogoCompany from './pages/Home/LogoCompany';
import { StyledContainer } from "config/styles";
import { useStyles } from './AppStyles';
import { AuthContext } from 'context/AuthContext'
import MetaData from './services/MetaData';


const history = createBrowserHistory();

const Routes = () => {
	const classes = useStyles();
	const authContent = useContext(AuthContext);
	const { resultHomeData } = authContent
	const [background, setBackground] = useState(Background.home);
	const [showTimer, setShowTimer] = useState(true);

	
	useEffect(()=> {
		const pathName = window.location.pathname;
		if (pathName.length > 2) {
			setShowTimer(false)
		} else {
			setShowTimer(true)
		}

		history.listen((location)=> {
			const pathName = location.pathname;
			if (pathName.length > 2) {
				setShowTimer(false)
			} else {
				setShowTimer(true)
			}
		})
	}, [])

	return (
		<RouterContext.Provider value={{ background: background, setBackground: setBackground }}>
			<Router history={history}>
				<MetaData />
				<div
					className={classes.Content}
					style={{ backgroundImage: `url(${background.src})` }}>
					<StyledContainer>
						<Menu />
						<LogoCompany isHomePage={showTimer} data={resultHomeData.timeNextDraw} />
						<br />
						<Switch>
							<Route exact path='/' name='Home' component={Home} />
							<Route exact path='/live' name='live score' component={Lives} />
							<Route exact path='/result' name='result' component={Results} />
							<Route path="*">
								<Redirect to="/" />
							</Route>
						</Switch>
					</StyledContainer>
				</div>
				<Footer />
			</Router>
		</RouterContext.Provider>
	);
}

export default Routes;
