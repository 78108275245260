import { makeStyles } from "@material-ui/core/styles";

import { color, font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  menu__list: {
    display: 'flex',
    padding: '20px 0 10px',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 0 0px',
    }
  },
  menu__routers: {
    display: 'flex',
    padding: '20px 0 10px',
    alignItems: 'center',
    justifyContent:'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5vw',
      width: '100%',
      height: '40px',
      padding: '0px 0px 0px 0px',
      backgroundColor: '#98c6e8',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.5vw',
      width: '100%',
      height: '30px',
      padding: '0px 0px 0px 0px',
      backgroundColor: '#98c6e8',
    }
  },
  menu__item_language: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginRight: '45px',
    color: color.black,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginRight: '0px',
      width: '34%',
      fontSize: '2.0vw',
      height: '100%',
      color: 'white',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginRight: '0px',
      width: '34%',
      fontSize: '2.5vw',
      height: '100%',
      color: 'white',
    }
  },

  menu__item: {
    marginRight: '45px',
    color: color.black,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '0px',
      width: '34%',
      fontSize: '2.0vw',
      height: '100%',
      // color: 'white',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '0px',
      width: '34%',
      fontSize: '2.5vw',
      height: '100%',
      // color: 'white',
    }
  },

  menu__item_selected: {
    display: 'flex',
    color: color.black,
    fontFamily: font.boldFamily,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      alignItems: 'center',
      // color: '#000',
      backgroundColor: '#fff',
      marginRight: '0px',
      width: '100%',
      height: '100%',
    }
  },

  menu__item_link: {
    display: 'flex',
    color: color.black,
    [theme.breakpoints.down('sm')]: {
      // color: 'white',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '0px',
      width: '100%',
      height: '100%',
    }
  }
}));
