const english = {
  translations: {
    "Date": "Date",
    "FooterCopyright": "COPYRIGHT © 2011 [company_name]. ALL RIGHTS RESERVED.",
    "Home": "Home",
    "Results": 'Results',
    'DrawNo': 'Draw No.',
    'Sun': 'Sunday',
    'Mon': 'Monday',
    'Tue': 'Tuesday',
    'Wed': 'Wednesday',
    'Thu': 'Thursday',
    'Fri': 'Friday',
    'Sat': 'Saturday',
    'No': 'No',
    'Result': 'Result', 
    'Prize': 'Prize',
    'TimeRemaning': 'TIME REMAINING',
    'CompanyName': 'SIAMPOOLSTODAY',
    'FooterText1': 'All Times Displayed In Indo Standar Time (IST is UTC+7) Unless Stated Otherwise. Please Note: Pricing On SiamPoolstoday.com Differs From That Found At Traditional Retail Lottery Agencies.',
    'FooterText2': 'Deposit Funds Into Your Player Account Via The Following Methods:',
    'WinningResults': 'WINNING RESULTS',
    'Live': 'Live',
    'DayDate': 'Date/Day',
    'NoResults': 'No Results',
    'Prize1st': 'Prize 1st',
    'Prize2nd': 'Prize 2nd',
    'Prize3rd': 'Prize 3rd'
 }
};

export default english;
