import React, { useState, useEffect, useContext} from "react";
import { Background, RouterContext } from 'context/RouterContext';
import { AuthContext } from 'context/AuthContext'
import Pagination from '@material-ui/lab/Pagination';
import { get, map } from 'lodash';
import moment from 'moment'
import {stringToArray} from './../../utils'
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';

import Api from 'services/Api';
import ResultTable from './ResultTable'
import RTable from './RTable'

import { useStyles , PaginationItemCustom } from './styles';

const Results = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const Router = useContext(RouterContext);
	const authContent = useContext(AuthContext);
	const [startDate, setDateSelected] = useState(new Date());
	const [openCalenader, setOpenCalender] = useState(false)
	const [mapDataSearch, setMapDataSearch] = useState(null)
	const [page, setPage] = useState(1)
	const [maxPage, setMaxPage] = useState(0)
	const [dataMap, setDataMap] = useState({})
	
	useEffect(()=> {
		Router.setBackground(Background.other);
	}, [Router])

	useEffect(() => {
		Api.requestPageResult(page).then((res) => {
			const isSuccess = get(res, 'data.success', false);
			if (!isSuccess) {
				// can not get data
				//render maintainer page
			} else {
				const data = get(res, 'data.data', {});
				const dMap = mapDataDefaultToTable(data)
				setDataMap(dMap);
				setMaxPage(data.total_size)
			}
		});

		authContent.resultFunc =  () => {
			setMapDataSearch(null)
		}
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t, page])

	const handleChange = (event, p) => {
		if (page !== p) {
			setPage(p)
		}
	}

	const dateTostringUTCDDMMYYYY = (date)=> {
		if (date) {
			return moment(date).format("DD/MM/YYYY")
		}

		return ''
	}

	const handleChangeDate = (date)=> {
		setDateSelected(date)
		handleCloseCalender();
		
		const stringDate = dateTostringUTCDDMMYYYY(date);
		Api.requestPageResultSearch(stringDate).then((res) => {
			const isSuccess = get(res, 'data.success', false);
			if (!isSuccess) {
				// can not get data
				//render maintainer page
			} else {
				const data = get(res, 'data.data', {});
				const dMap = mapDataToTable(data)
				setMapDataSearch(dMap);
			}
		});
	}

	const handleCloseCalender = ()=>{
		setOpenCalender(false)
	}

	const handleOpenCalender = ()=> {
		setOpenCalender(true)
	}

	const mapDataDefaultToTable = (data) => {
		const results = get(data, 'result', [])
		const header = [t('DayDate'), t('Prize1st'), t('Prize2nd'), t('Prize3rd')]
		const body = map(results, (item, index) => {
			const singlePrizeListData = get(item, 'singlePrizeList', [])
			const date = <><b> {item.day_of_week} </b> {item.date} </>
			return [date,  ...map(singlePrizeListData, (r, index)=> {
				return stringToArray(r.result)
			})]
		})
		return {
			header,
			body
		}
	}

	const mapDataToTable = (data) => {
		const results = get(data, 'result', {})
		const prizeListData = get(results, 'prizeList', [])
		const singlePrizeListData = get(results, 'singlePrizeList', [])
		let headerTemp = [t('Prize1st'), t('Prize2nd'), t('Prize3rd')]
		if (prizeListData.length <= 0 && singlePrizeListData.length <=0) {
			const header = [t('DayDate'), ...headerTemp];
			return {
				singlePrizes: {
					header,
					body: []
				},
				prizeList: []
			}
		}
		
		const date = <><b> {data.day_of_week} </b> {data.date} </>
		const body = [[date,  ...map(singlePrizeListData, (r, index)=> {
				headerTemp[index] = r.name
				return stringToArray(r.result)
			})]]
		
		const prizeList = map(prizeListData, (item, index) => {
			return {
				...item,
				id: index,
				resuls: item.result.map((ressultString) => {
					return stringToArray(ressultString)
				})
			}
		})
		
		headerTemp = headerTemp.slice(0, 3);
		const header = [t('DayDate'), ...headerTemp];
		return {
			singlePrizes: {
				header,
				body
			},
			prizeList
		}
	}

	const renderContent = ()=> {
		if (mapDataSearch) {
			return <>
				<RTable data={mapDataSearch.singlePrizes} level ={1} />
				{map(mapDataSearch.prizeList, (item, index) => {
					return <ResultTable key={index} index={index} data={item} />
				})}
			</>
		} else {
			return <>
				<RTable data={dataMap} level ={1} />
				<br />
				<Pagination renderItem={(item)=> <PaginationItemCustom {...item} className={classes.PaginationClass} /> } size='small' shape="rounded" color="primary" count={maxPage} page={page} onChange={handleChange} />
			</>
		}
	}

	const CustomInputDatePicker = React.forwardRef(({ value, onClick }, ref) => (
		<Button
			ref={ref}
			className={classes.date__picker} 
			onClick={onClick}
			variant="contained"
			color="primary"
			endIcon={<SearchIcon />}> 
				{value}
	  	</Button>
	));

	return (
		<div className={classes.container_page} style={{ animation: 'fadeIn ease-in .1s' }}>
			<div className={classes.container}>
				<div className={classes.search__containner}>
					<DatePicker
						className={classes.date__picker}
						open={openCalenader}
						name='date'
						id='date'
						dateFormat='E, d MMM y'
						maxDate={new Date()}
						selected={startDate}
						onChange={handleChangeDate}
						onClickOutside={handleCloseCalender}
						onInputClick={handleOpenCalender}
						customInput={<CustomInputDatePicker />}
					/>
				</div>
				{renderContent()}
			</div>
		</div>
	)
};



export default Results